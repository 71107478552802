import axios from 'axios';
import {PMC} from '@/config/constants.config';
import {AlertService} from '@/service/alert.service';
import {UtilityService} from '@/service/utility.service';

const initVesselState = () => {
  return {
    vessel: {},
    vessels: [],
    newVessel: {
      image: null,
      code: '',
      name: '',
      alias: '',
      vesselType: '',
      flag: '',
      beneficial: '',
      status: 1,
      owner: '',
      gearOwmer: '',
      manning: '',
      tradeRoute: '',
      union: '',
      endServiceReason: '',
      dateDelivered: '',
      dateReDelivered: '',
      builtYear: '',
      classification: '',
      contractLength: '',
      maxOverTime: '',
      hrsPerWeek: '',
      kw: '',
      grt: '',
      dwt: '',
      imoNo: '',
      offNo: '',
    },
    formSearchData: {
      page: 1,
      count: 10,
      code: '',
      name: '',
      vesselType: '',
      vesselStatus: 1,
    },
    vesselImage : null,
  };
};

/** state **/
const state = {
  ...initVesselState(),
};

/** mutations **/
const mutations = {
  set_vessel(state, payload) {
    state.vessel = payload;
  },

  set_vessels(state, payload) {
    state.vessels = payload;
  },

  create_vessel(state, payload) {
    state.newVessel = {};
  },

  update_vessel(state, payload) {
    const vessel = state.vessels.find((v, k) => v.id === payload.id);
    Object.assign(vessel,payload);
  },

  delete_vessel(state, payload) {
    let index = state.vessels.findIndex((v, k) => v.id === payload.id);
    state.vessels.splice(index, 1);
  },

  reset_vessel_state(state) {
    Object.assign(state, initVesselState());
  },

  set_vessel_image(state,payload){
    state.vesselImage  = payload;
  },

  null_vessel_image(state){
    state.vesselImage = null;
  },

  reset_vessel(state){
    state.vessel = {}
  },

  reset_new_vessel(state){
    state.newVessel = {}
  },
};

/** actions **/
const actions = {
  async getVesselListPaginated({commit}, payload) {
    commit('set_is_loading',true)
    return await axios.get(`${PMC.VESSEL}`, {
      params : {
        page: payload.page,
        count: payload.count,
        code: payload.code,
        name: payload.name,
        vesselType: payload.vesselType,
        vesselStatus: payload.vesselStatus,
      }
    }).then((r) => {

      commit('set_vessel_pagination', r.data.vessels);
      commit('set_vessels', r.data.vessels.data);
      commit('set_obj_vessel_types', r.data.vesselTypeKeys);
      commit('set_obj_flags',r.data.flagKeys);
      commit('set_obj_owner',r.data.ownerKeys);
      commit('set_obj_principal',r.data.principalKeys);
      commit('set_obj_manning',r.data.manningKeys);
      commit('set_obj_engineType',r.data.vesselEngineTypeKeys);
      commit('set_obj_beneficiary',r.data.beneficialKeys)
      commit('set_obj_trade_route',r.data.tradeRouteKeys)
      commit('set_obj_union',r.data.unionKeys)
      commit('set_is_loading',false)
      commit('set_obj_engine_types',r.data.engineTypesKeys);
      commit('set_obj_vessel_classification',r.data.vesselClassKeys);
      commit('set_obj_ecdis',r.data.ecdisKeys)
      commit('set_obj_employee',r.data.employeeKeys)

      return true;

    }).catch((er) => {
      commit('set_is_loading',false)
      AlertService.errorWithResponseDataService(er);
      return false;

    });
  },

  createVessel({commit}, payload) {
    return axios.post(PMC.CREATE_VESSEL, payload, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    }).then((r) => {
      commit('create_vessel', r.data);
      return true;

    }).catch((er) => {
      UtilityService.generateResponseMessage(er)
      return false;

    });
  },

  getVesselDetails({commit}, payload) {
    return  axios.get(`${PMC.VESSEL}/${payload}`).then((r) => {
      commit('set_vessel', r.data);
      return true;

    }).catch((er) => {
      AlertService.errorWithResponseDataService(er);
      return false;

    });
  },

  updateVessel({commit}, payload) {
    return axios.post(`${PMC.UPDATE_VESSEL}/${payload.get('id')}`,payload,{
      headers: {
        'Content-type': 'multipart/form-data',
      },
    }).then((r)=>{
      commit('update_vessel',r.data.vessel)
      return true;

    }).catch((er)=>{
      UtilityService.generateResponseMessage(er)
      return false;

    });
  },

  deleteVessel({commit}, payload) {
    return axios.delete(`${PMC.VESSEL}/${payload}`).then((r) => {
      commit('delete_vessel',r.data);
      return true;
    }).catch((er) => {
      AlertService.errorWithResponseDataService(er);
      return false;
    });
  },

  //todo delete vessel image
  deleteVesselImage({commit},payload){
    return axios.delete(PMC.DELETE_VESSEL_IMAGE).then((r)=>{
      commit('update_vessel',r.data)
      return true;

    }).catch((er)=>{
      AlertService.errorWithResponseDataService(er)
      return false;

    });
  },

  resetVesselState({commit}) {
    commit('reset_vessel_state');
  },

  setVessel({commit},payload){
    commit('set_vessel',payload)
  },

  resetUpdateVessel({commit}, payload) {
    commit('update_vessel',payload)
  },

  //todo deleteVesselPhoto
  deleteVesselPhoto({commit},payload){},

  setVesselImage({commit}, payload) {
    commit('set_vessel_image',payload)
  },

  null_vessel_image({commit}){
    commit('null_vessel_image')
  },

  resetVessel({commit}){
    commit('reset_vessel')
  },

  resetNewVessel({commit}){
    commit('reset_new_vessel')
  }
};

/** getters **/
const getters = {
  vessel: state => UtilityService.capitalizeProperties(state.vessel),
  vessels: state => UtilityService.capitalizeProperties(state.vessels),
  newVessel: state => state.newVessel,
  formSearchData: state => state.formSearchData,
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
